export default defineNuxtPlugin((nuxtApp) => {
  let observer: IntersectionObserver | null = null

  const setBackgroundImage = (el: HTMLElement): void => {
    const imageUrl = el.getAttribute('data-bg')
    if (imageUrl) {
      el.style.backgroundImage = `url(${imageUrl})`
      el.removeAttribute('data-bg')
    }
  }

  if (process.client) {
    observer = new IntersectionObserver((entries, self) => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          setBackgroundImage(target as HTMLElement)
          self.unobserve(target)
        }
      })
    })
  }

  nuxtApp.vueApp.directive('lazy-background', {
    mounted (el: HTMLElement) {
      if (observer) {
        observer.observe(el)
      }
    },
    updated (el: HTMLElement) {
      if (observer) {
        observer.observe(el)
      }
    }
  })
})
