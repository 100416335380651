<template>
  <button
    v-if="showButton"
    class="fixed bottom-10 right-10 bg-gray-300 text-gray-700 rounded-full w-12 h-12 flex justify-center items-center shadow-md transition-opacity hover:opacity-80 z-20"
    @click="scrollToTop"
  >
    <ArrowUpIcon class="h-[24px] w-[28px] text-[#151313] font-bold" />
  </button>
</template>

<script setup>
import { ArrowUpIcon } from '@heroicons/vue/24/outline'

import { useWindowScroll } from '@vueuse/core'

const { y } = useWindowScroll()
const showButton = ref(false)

watch(y, (newY) => {
  showButton.value = newY > 200
})

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
