export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"src":"https://maps.googleapis.com/maps/api/js?key=AIzaSyBBeyIpGuw1ld1dAI5ymZc_qBIqVntntFI&libraries=places&loading=async","async":true,"defer":true},{"innerHTML":"\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            gtag('js', new Date());\n            gtag('config', 'G-PDDZKB1SHJ');\n            gtag('config', 'AW-11440472710/2iX9CMDp6osZEIaFn88q', {\n              'phone_conversion_number': '770-629-9987'\n            });\n          ","tagPosition":"head"},{"innerHTML":"\n            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n            })(window,document,'script','dataLayer','GTM-MQMZW8RX');\n          ","tagPosition":"head"}],"noscript":[{"innerHTML":"\n            <iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MQMZW8RX\"\n            height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>\n          ","tagPosition":"bodyOpen"}],"htmlAttrs":{"lang":"en"},"title":"Canopy Roofing","charset":"utf-8"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false