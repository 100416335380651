import * as Sentry from '@sentry/vue'
import * as flatted from 'flatted'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { $logtail } = useNuxtApp()

  const { public: { sentry } } = useRuntimeConfig()
  const tracingOrigins = [
    'localhost',
    /^https:\/\/([a-z0-9-]+\.)*gocanopyroofing\.com$/,
    /^https:\/\/white-rock-01d431e0f-\d+\.eastus2\.azurestaticapps\.net$/
  ]

  if (!sentry.dsn) {
    return
  }

  Sentry.init({
    attachProps: true,
    logErrors: true,
    trackComponents: true,
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment ?? 'development',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        routeLabel: 'path'
      }), // Add browser tracing integration
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false
      }), // Add replay integration
      Sentry.metrics.metricsAggregatorIntegration(), // Add metrics integration
      Sentry.browserProfilingIntegration() // Add browser profiling integration
    ],
    tracesSampleRate: 0.5,
    tracePropagationTargets: tracingOrigins,
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 0.8,
    profilesSampleRate: 0.5
  })

  function safeOverrideConsoleMethod (method) {
    const originalConsoleMethod = console[method]
    if (originalConsoleMethod) {
      console[method] = function (...args) {
        const message = args.map(arg => (typeof arg === 'object' && arg !== null ? flatted.stringify(arg) : arg)).join(' ')
        if (method === 'error') {
          if (message && message !== '[{}]') {
            Sentry.captureException(new Error(message))
          }

          $logtail?.error(message, {
            source: 'client',
            method,
            args
          })
          $logtail?.debug(message, {
            source: 'client',
            method,
            args
          })
        }
        // else if (method === 'warn') {
        //   $logtail?.warn(message, {
        //     source: 'client',
        //     method,
        //     args
        //   })
        // }
        else if (method === 'trace') {
          $logtail?.trace(message, {
            source: 'client',
            method,
            args
          })
        } else if (method === 'log' || method === 'info') {
          $logtail?.info(message, {
            source: 'client',
            method,
            args
          })
        } else {
          $logtail?.debug(message, {
            source: 'client',
            method,
            args
          })
        }
        $logtail?.flush()
        originalConsoleMethod.apply(console, args)
      }
    }
  }
  // ['log', 'info', 'warn', 'error', 'debug', 'trace']
  ['log', 'info', 'error', 'debug', 'trace'].forEach(safeOverrideConsoleMethod)

  // Automatically capture console messages
  // Sentry.inboundFiltersIntegration({
  //   ignoreErrors: [],
  //   ignoreInternal: false,
  //   disableErrorDefaults: false,
  //   disableTransactionDefaults: false,
  //   allowUrls: tracingOrigins
  // })
  // Sentry.breadcrumbsIntegration({
  //   console: true
  // })
  // Sentry.captureConsoleIntegration()

  nuxtApp.vueApp.config.errorHandler = (err, vm, info) => {
    Sentry.captureException(err)
    // console.error(err)
    $logtail?.error(err, {
      errType:'errorHandler',
      source: 'client',
      vm,
      info
    })
    $logtail?.debug(err, {
      errType:'errorHandler',
      source: 'client',
      vm,
      info
    })
    $logtail?.flush()
  }

  window.onerror = function (message, source, lineno, colno, error) {
    Sentry.captureException(error || new Error(message))
    $logtail?.error(error,{
      errType:'onerror',
      source: 'client',
      message,
      errorSource: source,
      lineno,
      colno
    })
    $logtail?.debug(error, {
      errType:'onerror',
      source: 'client',
      errorSource: source,
      lineno,
      colno
    })
    $logtail?.flush()
  }

  window.onunhandledrejection = function (event) {
    Sentry.captureException(event.reason)
    $logtail?.error(event.reason, {
      errType:'onunhandledrejection',
      source: 'client'
    })
    $logtail?.debug(event.reason, {
      errType:'onunhandledrejection',
      source: 'client'
    })
    $logtail?.flush()
  }
})
